import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalStorageService } from 'ngx-webstorage';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isUserLoggedIn = false
  constructor(
    private localStorage: LocalStorageService,
    private authService: AuthServiceService,
    private authenticationService: AuthenticationService,
    private router: Router
    
   
    ) { }

  ngOnInit(): void {
    this.authenticationService.isUserLoggedIn.subscribe(user => {
      this.isUserLoggedIn = user
    })
    this.isUserLoggedIn = this.authenticationService.getAuthToken() ? true : false
    this.checkToken()

  }
  checkToken() {
    let authorization = this.authenticationService.getAuthToken()
    const jwtHelper = new JwtHelperService();
    const decodedToken = jwtHelper.decodeToken(this.authenticationService.getAuthToken())
    const refreshToken = jwtHelper.decodeToken(this.authenticationService.getRefreshToken())
    if (decodedToken) {
      const tokenExpiry = decodedToken.exp
      const refreshtokenExpiry = refreshToken.exp

      if (tokenExpiry != null || tokenExpiry != '' || tokenExpiry != undefined) {
        const currentTime = Date.now().valueOf() / 1000

        if (currentTime > tokenExpiry) {

          if (currentTime > refreshtokenExpiry) {

            this.localStorage.clear()
            // this.cookie.deleteAll()
            this.router.navigate(['/auth/login'])
            setTimeout(() => {
              window.location.reload()
            })
          }
          else {

            this.authService.getRefreshToken().subscribe(
              (resp: any) => {
                if (resp) {

                  this.authenticationService.setAuthToken(resp.token, resp.refreshToken)
                  window.location.reload()
                }
              }, error => {

              }
            )

          }
        }
      }

    }
    return authorization
  }
  logOut(): void {
    this.authenticationService.clearToken()
  }
 
  }


