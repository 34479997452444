import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt'
@Injectable()
export class CustomInterceptorInterceptor implements HttpInterceptor {

  constructor(private localStorage: LocalStorageService,
    private authService: AuthServiceService,
    private authenticationService: AuthenticationService,
    private router: Router) { }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let authorization
    if (req.url.endsWith('/auth/refreshToken')) {
      authorization = this.authenticationService.getRefreshToken()
   
      this.authenticationService.setAuthToken(authorization)
      if (authorization) {
        req = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + authorization)
        }), (error: { error: any; }) => {

        }
      }
    } else {
      authorization = this.checkToken()
      if (authorization) {
        req = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + authorization)
        }), (error: { error: any; }) => {

        }
      }
    }
    return next.handle(req)
  }

  checkToken() {
    let authorization = this.authenticationService.getAuthToken()
    const jwtHelper = new JwtHelperService();
    const decodedToken = jwtHelper.decodeToken(this.authenticationService.getAuthToken())
    const refreshToken = jwtHelper.decodeToken(this.authenticationService.getRefreshToken())
    if (decodedToken) {
      const tokenExpiry = decodedToken.exp
      const refreshtokenExpiry = refreshToken.exp

      if (tokenExpiry != null || tokenExpiry != '' || tokenExpiry != undefined) {
        const currentTime = Date.now().valueOf() / 1000

        if (currentTime > tokenExpiry) {

          if (currentTime > refreshtokenExpiry) {

            this.localStorage.clear()
           
            this.router.navigate(['/auth/login'])
            setTimeout(() => {
              window.location.reload()
            })
          }
          else {

            this.authService.getRefreshToken().subscribe(
              (resp: any) => {
                if (resp) {

                  this.authenticationService.setAuthToken(resp.token, resp.refreshToken)
                  window.location.reload()
                }
              }, error => {

              }
            )

          }
        }
      }

    }
    return authorization
  }
}
