import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuardGuard } from './services/auth-guard.guard'

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/home-module/home-module.module').then(h => h.HomeModuleModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/pre-login-module/pre-login-module.module').then(l => l.PreLoginModuleModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./modules/post-login-module/account-module.module').then(a => a.AccountModuleModule), canLoad: [AuthGuardGuard]
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
