import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { JwtHelperService } from '@auth0/angular-jwt'
import { LocalStorageService } from 'ngx-webstorage'
import { BehaviorSubject } from 'rxjs'
import { TokenPayload } from '../model/common-model'

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  private tokenKey = 'token'
  private refreshTokenKey = 'refreshToken'
  isUserLoggedIn = new BehaviorSubject<boolean>(false)
  constructor(
    private localStorage: LocalStorageService,
    private router: Router
    ) { }

  setAuthentication(token: string): void {
    this.setAuthToken(token)
  }

  setAuthToken(token: string, refreshToken: string|null = null): void {
    this.localStorage.store(this.tokenKey, token)
    this.isUserLoggedIn.next(true)
    this.localStorage.store(this.refreshTokenKey, refreshToken)
  }

  getAuthToken(): string {
    const token = this.localStorage.retrieve(this.tokenKey)
    return token ? token : ''
  }

  getRefreshToken(): string {
    const token = this.localStorage.retrieve(this.tokenKey)
    return token ? token : ''
  }

  accountInfo(): TokenPayload {
    const token = this.getAuthToken()
    const helper = new JwtHelperService()
    const decodedToken: TokenPayload = helper.decodeToken(token)
    return decodedToken
  }

  clearToken(): void {
    this.localStorage.clear(this.tokenKey)
    this.localStorage.clear(this.refreshTokenKey)
    this.router.navigate([''])
    setTimeout(() => {
      window.location.reload()
    })
  }

}
