<div class="container-fluid">
    <div class="row bg-success fTm">
        <div class="col-sm-12">
    
        </div>
        <div class="bg-dark mt-2">
            <div class="container-fluid">
                <div class="row text-light p-4">
                    <div class="col-sm-9">
                        <app-footer-info></app-footer-info>
                    </div>
                    <div class="col-sm-3">
                        <app-footer-icon></app-footer-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-accepted-payment-gateway></app-accepted-payment-gateway>