import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment.prod'
import { BusinessTypesRes } from '../model/common-model'
import { ApplicableBusinessTypeRes, RegisterReq } from '../model/register-model'

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {
  apiUrl = environment.apiUrl
  constructor(private http: HttpClient) { }

  getBusinessTypes(): Observable<BusinessTypesRes> {
    return this.http.get<BusinessTypesRes>(this.apiUrl + `/getBusinessTypes`)
  }

  getBusinessTypesWithParams(refId:number): Observable<BusinessTypesRes> {
    return this.http.get<BusinessTypesRes>(this.apiUrl + `/getBusinessTypes?refId=${refId}`)
  }

  privateregister(privateUserObj: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + `/registration`, privateUserObj)
  }
  getRefreshToken(): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/auth/getRefreshToken')
  }

  getEirCode(code: any): Observable<any> {
    return this.http.get<any>(this.apiUrl + `/getEirCode?code=${code}`)
  }

  getMasterPlan(): Observable<any> {
    return this.http.get<any>(this.apiUrl + `/getMasterPlan`)
  }

  standardUserRegistration(standardUserRegisterReq: RegisterReq): Observable<any> {
    return this.http.post<any>(this.apiUrl + `/registration`, standardUserRegisterReq)
  }

  getApplicableToBusiness(): Observable<ApplicableBusinessTypeRes> {
    return this.http.get<ApplicableBusinessTypeRes>(this.apiUrl + '/getApplicableToBusiness')
  }

  payment(paymentObj:any) {
    return this.http.post<any>(this.apiUrl + `/paymentGateway`,paymentObj)
  }
}
