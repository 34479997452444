import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-accepted-payment-gateway',
  templateUrl: './accepted-payment-gateway.component.html',
  styleUrls: ['./accepted-payment-gateway.component.css']
})
export class AcceptedPaymentGatewayComponent implements OnInit {
  currentYear = 0
  constructor() { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear()
  }

}
