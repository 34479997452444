<div class="container-fluid">
    <div class="row">
        <div class="col-sm-6 tradelocal">
            &copy; Trade Local {{currentYear}}
        </div>
        <div class="col-sm-6 text-right">
            <img class="logoimg"
                    src="../../../../assets/images/footer_logo.jpg">
        </div>
    </div>
</div>