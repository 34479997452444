<div class="row mt-4">
    <div class="col-sm-3 content">
        <b class="heading">Find More</b> <br>
        - Retail & Shopping <br>
        - Trade, Services & Professions <br>
        - Social, Dining & Recreation <br>
        - Classified Adverts <br>
        - Recruitement, Jobs & Training <br>
        - Notice Board <br>
    </div>
    <div class="col-sm-3 content">
        <b class="heading">Customer Service</b> <br>
        - Premium <br>
        - Public Adverts <br>
        - How to stay Safe Guide <br>
        - FAQ
    </div>
    <div class="col-sm-3 content">
        <b class="heading">Information</b> <br>
        - Terms and Conditions <br>
        - Returns <br>
        - Privacy Policy <br>
    </div>
    <div class="col-sm-3 content">
        <b class="heading">Our Story</b> <br>
        - Our Story <br>
        - Media <br>
        - Contact Us
    </div>
</div>