import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
  currentUrl = ''
  breadcrumb = ''
  constructor(private router: Router,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.currentUrl = this.router.url
 
    this.setBreadcrumb()
    this.router.events.subscribe(event => {
      this.currentUrl = this.router.url
      
      this.setBreadcrumb()
    })

    
  }

  setBreadcrumb(): void {
    switch (this.currentUrl) {
      case '/':
        this.breadcrumb = 'Home'
        break
      case '/account/user-list':
        this.breadcrumb = 'User List'
        break
      case '/auth/login':
        this.breadcrumb = 'Login'
        break
      case '/account/categories-list':
        this.breadcrumb = 'Categories List'
        break
      case '/account/terms':
        this.breadcrumb = 'Terms'
        break
      case '/account/messages':
        this.breadcrumb = 'Messages'
        break
        case '/account/advertising-banners':
          this.breadcrumb = 'Advertising Banners'
          break
      case '/account/banner':
        this.breadcrumb = 'Banner'
        break
      case '/account/control-ping':
        this.breadcrumb = 'Control Ping'
        break
        case '/account/import-eircode':
          this.breadcrumb = 'Import Eircode'
          break
          case '/account/cms':
            this.breadcrumb = 'Cms'
            break
            case '/account/export-report':
              this.breadcrumb = 'Export Report'
              break
              case '/account/advert-bundle':
                this.breadcrumb = 'Advert Bundles'
                break
      default:
        this.breadcrumb = 'Home'
    }
  }

  back(){
    window.history.back()
  }



}
