<div class="d-flex p-4 border w-100" >
    <div class="w-30">
        <span class="logo">
            <a routerLink="">
            <img class="logoimg"
                    src="../../../../assets/images/logo.png">
            </a>
        </span>

        
    </div>
    <div class="w-100" *ngIf="isUserLoggedIn">
        <ul class="nav">
            <li class="nav-item">
                <a class="nav-link" href="#">Home</a>
              </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="account/user-list">User List</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="account/categories-list">Category List</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="account/terms">Terms</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="account/messages">Message</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="account/advertising-banners">Advertising Banner</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="account/control-ping">Control Ping</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="account/banner">Banner</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="account/import-eircode">Import EIRCODE</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="account/cms">CMS</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="account/export-report">Export Report</a>
            </li><li class="nav-item">
              <a class="nav-link" routerLink="account/advert-bundle">Advert Bundles</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="account/advert-management">Advert management</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="account/post-updates-list">Post update</a>
            </li>
          </ul>
    </div>
    <div class="text-right navLinks w-60">
        <a *ngIf="!isUserLoggedIn" routerLink="auth/login" class="btn btn-green mr-3">Login</a>
        <button type="button" (click)="logOut()" class="btn btn-green mr-3 text-light" *ngIf="isUserLoggedIn"> Logout</button>
    </div>
</div>